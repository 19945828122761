html, body  {
  padding: 0;
  margin: 0;
  margin-top: -13px;
}

#rental-service-form {
    padding: 0;
    margin: 0;
    margin-top: -13px;

    label,
    input,
    button.btn {
      font-family: "Noto Sans", -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, Cantarell, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &:disabled {
        color: #888888;
        background: #b8b8b8;
        border: 1px solid rgba(79, 79, 79, 0.6);

        &:hover {
          color: #888888;
          background: #b8b8b8;
          border: 1px solid rgba(79, 79, 79, 0.6);
        }

        .icon-back-wrapper svg {
          fill: rgb(179, 148, 134) !important;
        }
    
        svg {
          fill: #888888;
        }
      }
    }

    button.btn,
    input[type="text"] {
      outline: none;
      font-size: 14px;
      -webkit-appearance: none;
      -webkit-border-radius: 0;
        -moz-border-radius: 0;
          border-radius: 0;
      -webkit-box-shadow: none;
        -moz-box-shadow: none;
          box-shadow: none;
      -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
    }

    input[type="text"] {
      width: 100%;
      border: 0;
      background: #f8f8fa;
      color: #000000;
      padding: 1.3em 0 0.2em;
      line-height: 1.5em;
      border-bottom: 1px solid #908f8f;
    }

    input[type="checkbox"] {
      height: 0;
      width: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      left: -100%;
      left: -100vw;
    }

    input[type="checkbox"]+label {
      font-size: 14px;
      padding-left: 34px;
      line-height: 2em;
      outline: none;
      display: block;
      cursor: pointer;
      -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
    }

    input[type="checkbox"]+label:focus {
      outline: none;
    }

    input[type="checkbox"]+label:before,
    input[type="checkbox"]+label:after {
      display: block;
      content: '';
      position: absolute;
      -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
    }

    input[type="checkbox"]+label:before {
      width: 20px;
      height: 20px;
      top: 50%;
      margin-top: -10px;
      left: 0;
      background: #b39486;
    }

    input[type="checkbox"]+label:after {
      content: "";
      height: 10px;
      width: 6px;
      top: 50%;
      left: 10px;
      opacity: 0;
      margin-top: -1px;
      position: absolute;
      border-color: #ffffff;
      border-style: solid;
      border-width: 0 3px 3px 0;
      background-clip: padding-box;
      -webkit-transform: translate(-50%,-60%) rotate(45deg);
        -moz-transform: translate(-50%,-60%) rotate(45deg);
          -o-transform: translate(-50%,-60%) rotate(45deg);
        -ms-transform: translate(-50%,-60%) rotate(45deg);
          transform: translate(-50%,-60%) rotate(45deg);
      -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
          border-radius: 3px;
    }

    input[type="checkbox"]:checked+label:after {
      opacity: 1;
    }

    input[type="checkbox"]+label a {
      color: #b39486;
      text-decoration: underline;
    }

    .form-item-checkbox {
      position: relative;
    }

    .form-item-namefield,
    .form-item-phonefield {
      margin-bottom: 40px;
      position: relative;
    }

    input[type="text"]+label {
      position: absolute;
      font-size: 14px;
      top: 19px;
      left: 0;
      font-weight: 700;
      text-transform: uppercase;
      color: #908f8f;
      -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
    }

    input[type="text"].nonempty+label {
      font-size: 12px;
      top: 0px;
    }

    input[type="text"].nonempty {
      border-bottom-color: #4f4f4f;
    }

    .form-bottom-item {
      display: flex;
      align-items: center;
    }

    button.btn {
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      letter-spacing: 2px;
      background: #f8f8fa;
      border: 1px solid rgba(79, 79, 79, 0.6);
      color: #4f4f4f;
      font-size: 14px;
      line-height: 3.5em;
      font-weight: 700;
      padding: 0 2em;
      cursor: pointer;
      margin-left: auto;
    }

    .btn-icon,
    button.btn .btn-icon-back,
    button.btn .icon-back-wrapper {
      -webkit-transition: transform .6s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: transform .6s cubic-bezier(.165, .84, .44, 1);
          transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    }

    .btn-icon, .btn-text {
      position: relative;
      z-index: 1;
    }

    .btn-icon {
      height: 12px;
      width: 32px;
      margin-left: 10em;
    }

    .btn-icon svg {
      position: absolute;
      right: 0;
      top: 0;
    }

    button.btn .icon-back-wrapper {
      -webkit-transform: translate3d(-103%, 0, 0);
        -moz-transform: translate3d(-103%, 0, 0);
          -o-transform: translate3d(-103%, 0, 0);
        -ms-transform: translate3d(-103%, 0, 0);
          transform: translate3d(-103%, 0, 0);
    }

    button.btn .icon-back-wrapper {
      position: absolute;
      width: 32px;
      height: 12px;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }

    button.btn:hover {
      background-color: #ffffff;
      border-color: #4f4f4f;
    }

    button.btn:active .btn-icon-back,
    button.btn:active .icon-back-wrapper,
    button.btn:focus .btn-icon-back,
    button.btn:focus .icon-back-wrapper,
    button.btn:hover .btn-icon-back,
    button.btn:hover .icon-back-wrapper {
      -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
          -o-transform: translateZ(0);
        -ms-transform: translateZ(0);
          transform: translateZ(0);
    }

    button.btn .btn-icon-back {
      -webkit-transform: translate3d(103%, 0, 0);
        -moz-transform: translate3d(103%, 0, 0);
          -o-transform: translate3d(103%, 0, 0);
        -ms-transform: translate3d(103%, 0, 0);
          transform: translate3d(103%, 0, 0);
    }

    .error {
      position: relative;
      left: 0;
      bottom: -6px;
      line-height: 1em;
      font-size: 14px;
      height: 0;
      padding-top: 0;
    }

    @media only screen and (max-width: 600px) {
      .form-bottom-item {
        flex-direction: column;
        align-items: flex-start;
      }

      button.btn {
        width: 100%;
        margin-top: 20px;
      }

      .btn-icon {
        margin-left: auto;
      }

      .error {
        bottom: -6px;
        font-size: 12px;
      }
    }

    @media only screen and (min-width: 720px) {
      button.btn,
      input[type="text"],
      input[type="text"]+label,
      input[type="checkbox"]+label {
        font-size: 20px;
      }
      input[type="text"]+label {
        top: 28px;
      }
      input[type="text"].nonempty+label {
        font-size: 16px;
      }
      .btn-icon {
        margin-left: 2em;
      }
    }

    @media only screen and (min-width: 2560px) {
      .btn-icon {
        margin-left: 4em;
      }

      .finish-button {
        .btn-icon {
          margin-left: 4em;
        }
      }
    }

}

#rental-service-form.finish {
  padding: 20px;
  padding-bottom: 47px;
  padding-top: 34px;
  margin: 0 auto;

  b {
    font-size: 28px;
    color: #444444;
    font-weight: 600;
  }

  p {
    font-size: 20px;
    color: #404040;
    font-weight: 400;
  }

  button {
    margin-top: 5px;
    width: 200px;
    line-height: 60px;
    padding: 0 28px;

    .btn-icon {
      margin-left: 5em;
    }
  }
}

@media only screen
  and (max-width: 864px) {
  #rental-service-form.finish {
    padding: 20px;
    padding-bottom: 47px;
    padding-top: 34px;
    margin: 0 auto;

    b {
      font-size: 18px;
    }

    p {
      font-size: 16px;
    }

    button {
      margin-top: 5px;
      width: 160px;
      line-height: 50px;
      padding: 0 28px;

      .btn-text {
        font-size: 18px;
      }

      .btn-icon {
        margin-left: 4em;
      }
    }
  }

  #rental-service-form {
    input[type="text"] {
      font-size: 16px;
    }
  }
}

@media only screen
  and (min-width: 720px)
  and (max-width: 720px) {
  #rental-service-form.finish {
    padding: 20px;
    padding-bottom: 47px;
    padding-top: 34px;
    margin: 0 auto;

    b {
      font-size: 28px;
    }

    p {
      font-size: 20px;
    }

    button {
      margin-top: 5px;
      width: 200px;
      line-height: 60px;
      padding: 0 28px;

      .btn-text {
        font-size: 18px;
      }

      .btn-icon {
        margin-left: 4em;
      }
    }

    p:not(:first-child) {
      padding-top: 40px;
    }
  }

  #rental-service-form {
    input[type="text"] {
      font-size: 20px;
    }
  }
}
