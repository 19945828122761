html, body {
  padding: 0;
  margin: 0;
}

#get-in-touch-i3q-purchase-form {
    padding: 0;
    margin: 0;
    margin-top: -27px;
    background-color: #fff;

    label,
    input,
    button.btn {
      font-family: "Noto Sans", -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, Cantarell, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      .btn-icon-front {
        fill: #E3813B !important;
      }

      &:disabled {
        color: #888888;
        background: #b8b8b8;
        border: 2px solid rgba(79, 79, 79, 0.6);

        &:hover {
          color: #888888;
          background: #b8b8b8;
          border: 2px solid rgba(79, 79, 79, 0.6);
        }

        .btn-icon-front {
          fill: rgb(179, 148, 134) !important;
        }

        .icon-back-wrapper svg {
          fill: rgb(179, 148, 134) !important;
        }

        svg {
          fill: #888888;
        }
      }
    }

    button.btn,
    input[type="text"] {
      outline: none;
      font-size: 22px;
      -webkit-appearance: none;
      -webkit-border-radius: 0;
        -moz-border-radius: 0;
          border-radius: 0;
      -webkit-box-shadow: none;
        -moz-box-shadow: none;
          box-shadow: none;
      -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
    }

    input[type="text"] {
      width: 100%;
      border: 0;
      background: #fff;
      color: #323232;
      padding: 1.3em 0 0.2em;
      line-height: 1.5em;
      border-bottom: 1px solid #908f8f;
    }

    input[type="checkbox"] {
      height: 0;
      width: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      left: -100%;
      left: -100vw;
    }

    input[type="checkbox"]+label {
      font-size: 18px;
      color: #222222;
      padding-left: 34px;
      line-height: 2em;
      outline: none;
      display: block;
      cursor: pointer;
      -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
    }

    input[type="checkbox"]+label:focus {
      outline: none;
    }

    input[type="checkbox"]+label:before,
    input[type="checkbox"]+label:after {
      display: block;
      content: '';
      position: absolute;
      -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
    }

    input[type="checkbox"]+label:before {
      width: 20px;
      height: 20px;
      top: 8px;
      left: 0;
      background: #E3813B;
    }

    input[type="checkbox"]+label:after {
      content: "";
      height: 10px;
      width: 6px;
      top: 17px;
      left: 10px;
      opacity: 0;
      position: absolute;
      border-color: #ffffff;
      border-style: solid;
      border-width: 0 3px 3px 0;
      background-clip: padding-box;
      -webkit-transform: translate(-50%,-60%) rotate(45deg);
        -moz-transform: translate(-50%,-60%) rotate(45deg);
          -o-transform: translate(-50%,-60%) rotate(45deg);
        -ms-transform: translate(-50%,-60%) rotate(45deg);
          transform: translate(-50%,-60%) rotate(45deg);
      -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
          border-radius: 3px;
    }

    input[type="checkbox"]:checked+label:after {
      opacity: 1;
    }

    input[type="checkbox"]+label a {
      color: #222222;
      text-decoration: none;
      font-weight: 700;
    }

    input[type="checkbox"]+label a:hover {
      text-decoration: underline;
    }

    .form-item-checkbox {
      position: relative;
    }

    .form-item-namefield,
    .form-item-phonefield {
      margin-bottom: 40px;
      position: relative;
    }

    input[type="text"]+label {
      position: absolute;
      font-size: 22px;
      top: 28px;
      left: 0;
      font-weight: 700;
      color: #1E1E1E;
      -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
    }

    input[type="text"].nonempty+label {
      font-size: 18px;
      top: 0px;
    }

    input[type="text"].nonempty {
      border-bottom-color: #4f4f4f;
    }

    button.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      bottom: 4px;
      text-transform: uppercase;
      letter-spacing: 2px;
      background-color: transparent;
      border: 2px solid #908f8f;
      color: #323232;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      margin: 40px auto 0 auto;
      padding: 20px 62px;
    }

    .btn-icon,
    button.btn .btn-icon-back,
    button.btn .icon-back-wrapper {
      -webkit-transition: transform .6s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: transform .6s cubic-bezier(.165, .84, .44, 1);
          transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    }

    .btn-icon, .btn-text {
      position: relative;
      z-index: 1;
    }

    .btn-icon {
      height: 12px;
      width: 32px;
      margin-left: 12px;
    }

    .btn-icon svg {
      position: absolute;
      right: 0;
      top: 0;
    }

    button.btn .icon-back-wrapper {
      -webkit-transform: translate3d(-103%, 0, 0);
        -moz-transform: translate3d(-103%, 0, 0);
          -o-transform: translate3d(-103%, 0, 0);
        -ms-transform: translate3d(-103%, 0, 0);
          transform: translate3d(-103%, 0, 0);
    }

    button.btn .icon-back-wrapper {
      position: absolute;
      width: 32px;
      height: 12px;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }

    button.btn:hover {
      background-color: #E3813B0F;
      border-color: #4f4f4f;
    }

    button.btn:active .btn-icon-back,
    button.btn:active .icon-back-wrapper,
    button.btn:focus .btn-icon-back,
    button.btn:focus .icon-back-wrapper,
    button.btn:hover .btn-icon-back,
    button.btn:hover .icon-back-wrapper {
      -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
          -o-transform: translateZ(0);
        -ms-transform: translateZ(0);
          transform: translateZ(0);
    }

    button.btn .btn-icon-back {
      -webkit-transform: translate3d(103%, 0, 0);
        -moz-transform: translate3d(103%, 0, 0);
          -o-transform: translate3d(103%, 0, 0);
        -ms-transform: translate3d(103%, 0, 0);
          transform: translate3d(103%, 0, 0);
    }

    .error {
      position: relative;
      left: 0;
      bottom: -6px;
      line-height: 1em;
      font-size: 14px;
      height: 0;
      padding-top: 0;
    }

    .unexpected-error {
      position: absolute;
      width: 100%;
      text-align: center;
      color: #FF0000;
      font-size: 14px;
      bottom: 84px;
    }

    .error-border {
      border-color: red !important;
    }

    @media only screen and (max-width: 540px) {
      input[type="text"],
      input[type="text"]+label {
        font-size: 20px;
      }
      input[type="text"]+label {
        top: 28px;
      }
      input[type="text"].nonempty+label {
        font-size: 16px;
      }
      input[type="checkbox"]+label {
        font-size: 16px;
      }
      input[type="checkbox"]+label:before {
        top: 6px;
      }
      input[type="checkbox"]+label:after {
        top: 16px;
      }
      .error {
        bottom: -6px;
        font-size: 14px;
      }
      .unexpected-error {
        bottom: 80px;
      }
      button.btn {
        font-size: 18px;
        padding: 18px 52px;
      }
    }

    @media only screen and (max-width: 500px) {
      input[type="text"],
      input[type="text"]+label {
        font-size: 16px;
      }
      input[type="text"].nonempty+label {
        font-size: 14px;
      }
      input[type="checkbox"]+label {
        font-size: 16px;
      }
      .error {
        bottom: -6px;
        font-size: 13px;
      }
      .unexpected-error {
        font-size: 13px;
        bottom: 73px;
      }
      button.btn {
        font-size: 16px;
        padding: 16px 42px;
      }
    }

    @media only screen and (max-width: 412px) {
      button.btn {
        width: 100%;
      }
    }

    @media only screen and (max-width: 405px) {
      .unexpected-error {
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 374px) {
      .unexpected-error {
        bottom: 66px;
      }
      .unexpected-error.eng-error {
        bottom: 74px;
      }
    }

    @media only screen and (max-width: 297px) {
      input[type="checkbox"]+label {
        font-size: 14px;
      }
      input[type="checkbox"]+label:before {
        top: 4px;
      }
      input[type="checkbox"]+label:after {
        top: 14px;
      }
      .error {
        font-size: 12px;
      }
      .unexpected-error {
        bottom: 57px;
      }
      .unexpected-error.eng-error {
        bottom: 62px;
      }
      button.btn {
        font-size: 14px;
        padding: 12px 42px;
      }
    }

    @media only screen and (max-width: 264px) {
      input[type="checkbox"]+label:before {
        top: 4px;
      }
      input[type="checkbox"]+label:after {
        top: 14px;
      }
      .unexpected-error {
        bottom: 29px;
      }
      .unexpected-error.eng-error {
        bottom: 56px;
      }
    }
}

#get-in-touch-i3q-purchase-form.finish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 0 35px 0;
  margin: 0;
  background-color: #fff;

  img {
    width: 93px;
    height: 93px;
  }

  p {
    font-size: 22px;
    color: #404040;
    font-weight: 400;
    padding: 0;
    margin: 50px 0;
  }

  button {
    margin: 0;

    .btn-icon {
      margin-left: 5em;
    }
  }
}

@media only screen and (max-width: 540px) {
  #get-in-touch-i3q-purchase-form.finish {
    padding: 31px 0 32px 0;

    p {
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 500px) {
  #get-in-touch-i3q-purchase-form.finish {
    padding: 48px 0;

    img {
      width: 70px;
      height: 70px;
    }

    p {
      font-size: 18px;
      margin: 40px 0;
    }
  }
}

@media only screen and (max-width: 437px) {
  #get-in-touch-i3q-purchase-form.finish {
    padding: 47px 0;

    p {
      margin: 30px 0;
    }

    button.btn {
      width: auto;
    }
  }
}

@media only screen and (max-width: 266px) {
  #get-in-touch-i3q-purchase-form.finish {
    padding: 34px 0;
  }
}
